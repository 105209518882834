<template>

    <div class="login-bg flex-column">
      <div class="safe-area flex-row align-items-center justify-between flex-auto">
        <div></div>
        <div class="column margin-right-30">
          <div class="font-size-20">用户登录</div>
          <div class="margin-top-20">
              <el-form :model="userForm" ref="userForm" label-width="auto">
                <el-form-item prop="username"
                :rules="[{ required: true, message: '此项不能为空'}]"
                >
                  <el-input v-model.trim="userForm.username" prefix-icon="el-icon-user" autocomplete="off" placeholder="账号" @keyup.enter.native="submitForm('userForm')"></el-input>
                </el-form-item>
                <el-form-item prop="password"
                  :rules="[{ required: true, message: '此项不能为空'}]"
                >
                  <el-input type="password" v-model.trim="userForm.password" prefix-icon="el-icon-key" autocomplete="off" placeholder="密码" @keyup.enter.native="submitForm('userForm')"></el-input>
                </el-form-item>
                <!-- <el-form-item prop="code"
                  :rules="[{ required: true, message: '此项不能为空'}]"
                >
                  <div class="flex-row">
                    <el-input type="text" v-model.trim="userForm.code" prefix-icon="el-icon-key" autocomplete="off" placeholder="图形验证码" @keyup.enter.native="submitForm('userForm')"></el-input>
                    <el-image class="link" style="width: 130px; height: 40px; margin-left:10px;" :src="codeImg"  fit="fill" @click="codeImgRefresh"></el-image>
                  </div>
                </el-form-item> -->
              </el-form>
              <div class="flex-row margin-tb-20 ">
                <el-checkbox v-model="agreement">我已阅读并同意</el-checkbox>
                <div class="color-yellow link-text">《用户服务协议及隐私政策》</div>
              </div>

              <el-button type="primary" round style="width: 100%;" :loading="loading" @click="submitForm('userForm')">登 录</el-button>

              
          </div>
          <div class="flex-row justify-between margin-20 ">
            <div class="link-text">忘记密码</div>
            <div class="link-text">注册账号</div>
          </div>
          <div class="color-sub text-align-center margin-bottom-5">第三方登录</div>
          <div class="border-top flex-row justify-center font-size-24 padding-tb-20">
            <i class="fa fa-wechat link color-green"></i>
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import crypto from "../common/crypto";
import Fingerprint2 from 'fingerprintjs2'
export default {
  name: 'Login',
  components:{
    
  },
  data(){
    return {
      loading:false,
      userForm: {
        username:"",
        password: '',
        code:''
      },
      codeImg:'',
      agreement:false,//是否勾选同意协议
      deviceToken:''
    }
  },
  computed:{
    
  },
  created(){
    this.$store.commit('stateInit')
    this.codeImgRefresh()
    Fingerprint2.get((components)=>{
      const values = components.map(function(component,index) {
        if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
          return component.value.replace(/\bNetType\/\w+\b/, '')
        }
        return component.value
      })
      
      // 生成最终id murmur   
      this.deviceToken = Fingerprint2.x64hash128(values.join(''), 31)
    })

  },
  mounted(){

  },
  methods:{
    codeImgRefresh(){
      // this.codeImg=`${this.$server}/api/user/generate_validate?stamp=${Math.random()}`
    },
    
    loginByUsername(){
      this.loading=true
      let params= {
        ...this.$store.state.basicParams,
        user_name:this.userForm.username,
        pwd: this.userForm.password,
        device_token:this.deviceToken,
        // captcha:this.userForm.code
      }
      this.$instance.post('/user/web_login',this.$qs.stringify(params))
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.callBack(res.data.data)
        }
      })
      .catch(()=> {
        this.loading=false
      }); 
    },
    submitForm(formName) {
      if(!this.agreement){
        this.$message({message: '请勾选阅读同意《用户服务协议及隐私政策》',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      let input=document.getElementsByTagName('input')
      for(let i=0;i<input.length;i++){
        input[i].blur()
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginByUsername()
        } else {
          return false;
        }
      });
    },
    
    //登录回调
    callBack(res){
      let success=()=>{
        let loginRes={...res,success:true}
        try{
          localStorage.setItem('loginRes',crypto.encrypt(loginRes))
          localStorage.setItem('loginStamp',new Date().getTime())
        }catch{
          //
        }
        this.$store.commit('loginRes',loginRes)
       
        if(res.audit_state!=2){
          this.$confirm(res.audit_state==0?'您还未提交资料审核，部分功能应用受限！':'您提交的资料正在审核中，部分功能应用受限！', '资料审核提示', {
            customClass:"custom-msgbox",
            confirmButtonText: res.audit_state==0?'提交资料':'继续',
            cancelButtonText: '取消',
            type: 'warning'
          })
          .then(() => {
            if(res.audit_state==0){
              this.$root.jump({
                path:'/user'
              })
            }else{
              this.$router.replace({
                path: '/',
              })
            }  
          })
          .catch(() => {
            this.$router.replace({
              path: '/',
            })
          });
        }else{
          this.$router.replace({
            path: this.$route.query.redirect||'/',
          })
        }
      }
      success()
    },
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .login-bg{
    height: 600px;
    min-width: 1200px;
    background: url('../assets/images/login-bg.jpg');
  }
</style>
